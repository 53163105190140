import { useEffect, useState } from 'react';

import Audio from '../MediaType/Audio';
import Content from '../MediaType/Content';
import Image from '../MediaType/Image';
import Pdf from '../MediaType/Pdf';
import Video from '../MediaType/Video';
import Iframe from '../MediaType/Iframe';
import { placeholderImg } from '../../utils/links';

const Slider = ({ attributes, filteredItems, mixAllData, clientId, EImage, ButtonContent, EAudio, EVideo, galleryIcon, setOpen, setActiveIndex, isOpen, custom, activeIndex, ContentArea, Modal }) => {
    const { lightboxType, img, caption: captionPosition, layout, slider } = attributes;

    const [carousel, setCarousel] = useState(null);
    const [carouselItems, setCaroulelItems] = useState(filteredItems);

    useEffect(() => {
        carousel?.destroy();
        setCaroulelItems(filteredItems);

    }, [filteredItems, slider]);

    useEffect(() => {
        const cr = new Carousel(document.getElementById("lbbCarousel"), {
            Dots: false,
            Thumbs: {
                type: slider?.thumbs,
            },
        }, { Thumbs });

        setCarousel(cr);
    }, [carouselItems, layout, slider]);

    return <div className='f-carousel' id='lbbCarousel'>
        {carouselItems?.map((item, index) => {
            const { type, thumbnail, content, caption } = item || {};

            return <div key={index} className="f-carousel__slide flex flex-wrap gap-5 justify-center max-w-5xl mx-auto px-6 lbbContent_area" data-thumb-src={type === 'image' && content || thumbnail || placeholderImg}>

                <Image type={type} mixAllData={mixAllData} clientId={clientId} caption={caption} content={content} EImage={EImage} lightboxType={lightboxType} img={img} captionPosition={captionPosition} ButtonContent={ButtonContent} attributes={attributes} />

                <Audio type={type} mixAllData={mixAllData} clientId={clientId} caption={caption} content={content} EAudio={EAudio} lightboxType={lightboxType} img={img} thumbnail={thumbnail} captionPosition={captionPosition} galleryIcon={galleryIcon} ButtonContent={ButtonContent} attributes={attributes} />

                <Video type={type} content={content} mixAllData={mixAllData} clientId={clientId} caption={caption} EVideo={EVideo} lightboxType={lightboxType} img={img} thumbnail={thumbnail} captionPosition={captionPosition} galleryIcon={galleryIcon} ButtonContent={ButtonContent} index={index} attributes={attributes} />

                <Content type={type} lightboxType={lightboxType} img={img} thumbnail={thumbnail} setOpen={setOpen} setActiveIndex={setActiveIndex} index={index} caption={caption} captionPosition={captionPosition} galleryIcon={galleryIcon} ButtonContent={ButtonContent} isOpen={isOpen} activeIndex={activeIndex} Modal={Modal} clientId={clientId} ContentArea={ContentArea} item={item} custom={custom} />

                {/* new code start  */}
                <Pdf type={type} mixAllData={mixAllData} clientId={clientId} index={index} caption={caption} lightboxType={lightboxType} img={img} thumbnail={thumbnail} captionPosition={captionPosition} galleryIcon={galleryIcon} ButtonContent={ButtonContent} attributes={attributes} content={content} EAudio={EAudio} />

                <Iframe type={type} mixAllData={mixAllData} clientId={clientId} content={content} EAudio={EAudio} lightboxType={lightboxType} img={img} thumbnail={thumbnail} caption={caption} captionPosition={captionPosition} galleryIcon={galleryIcon} ButtonContent={ButtonContent} attributes={attributes} />
            </div>
        })}
    </div>

}
export default Slider;