import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Image from '../MediaType/Image';
import Audio from '../MediaType/Audio';
import Video from '../MediaType/Video';
import Content from '../MediaType/Content';
import Pdf from '../MediaType/Pdf';
import Iframe from '../MediaType/Iframe';


const MasonryLayout = ({ attributes, filteredItems, mixAllData, clientId, EImage, ButtonContent, EAudio, EVideo, galleryIcon, setOpen, setActiveIndex, isOpen, custom, activeIndex, ContentArea, Modal }) => {
    const { lightboxType, columnGap, rowGap, img, caption: captionPosition, columns } = attributes;
    const { desktop, tablet, mobile } = columns;
    return <ResponsiveMasonry columnsCountBreakPoints={{ 0: mobile, 576: tablet, 768: desktop }}>
        <Masonry gutter={`${rowGap} ${columnGap}`}>
            {filteredItems?.map((item, index) => {
                const { type, thumbnail, content, caption } = item || {};

                return <div key={index} className=" lbbContent_area">

                    <Image type={type} mixAllData={mixAllData} clientId={clientId} caption={caption} content={content} EImage={EImage} lightboxType={lightboxType} img={img} captionPosition={captionPosition} ButtonContent={ButtonContent} attributes={attributes} />

                    <Audio type={type} mixAllData={mixAllData} clientId={clientId} caption={caption} content={content} EAudio={EAudio} lightboxType={lightboxType} img={img} thumbnail={thumbnail} captionPosition={captionPosition} galleryIcon={galleryIcon} ButtonContent={ButtonContent} attributes={attributes} />

                    <Video type={type} content={content} mixAllData={mixAllData} clientId={clientId} caption={caption} EVideo={EVideo} lightboxType={lightboxType} img={img} thumbnail={thumbnail} captionPosition={captionPosition} galleryIcon={galleryIcon} ButtonContent={ButtonContent} index={index} attributes={attributes} />

                    <Content type={type} lightboxType={lightboxType} img={img} thumbnail={thumbnail} setOpen={setOpen} setActiveIndex={setActiveIndex} index={index} caption={caption} captionPosition={captionPosition} galleryIcon={galleryIcon} ButtonContent={ButtonContent} isOpen={isOpen} activeIndex={activeIndex} Modal={Modal} clientId={clientId} ContentArea={ContentArea} item={item} custom={custom} />

                    {/* new code start  */}
                    <Pdf type={type} mixAllData={mixAllData} clientId={clientId} index={index} caption={caption} lightboxType={lightboxType} img={img} thumbnail={thumbnail} captionPosition={captionPosition} galleryIcon={galleryIcon} ButtonContent={ButtonContent} attributes={attributes} content={content} EAudio={EAudio} />

                    <Iframe type={type} mixAllData={mixAllData} clientId={clientId} content={content} EAudio={EAudio} lightboxType={lightboxType} img={img} thumbnail={thumbnail} caption={caption} captionPosition={captionPosition} galleryIcon={galleryIcon} ButtonContent={ButtonContent} attributes={attributes} />
                </div>
            })}
        </Masonry>
    </ResponsiveMasonry>
}
export default MasonryLayout;