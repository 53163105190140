
import { useEffect, useState, useRef } from 'react';

import { lbbConfig } from './config';
import EImage from './assets/img/image.svg';
import EAudio from './assets/img/audio.svg';
import EVideo from './assets/img/video.svg';
import Default from './Components/layout/Default';
import MasonryLayout from './Components/layout/Masonry';
import Slider from './Components/layout/Slider';

const Lightbox = ({ ContentArea, attributes, isBackend = false, custom, clientId, Modal, index = 0 }) => {

	const { lightboxType, items, layout, columns, button, galleryIcon, popupOptions, cId, popupIconLeft, popupIconMiddle, popupIconRight, thumb, slideShow, options, controls } = attributes;
	const { mixAllData } = popupOptions;
	const [isOpen, setOpen] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);
	const containerRef = useRef();

	// funcyapps feature
	useEffect(() => {
		lbbConfig(clientId, popupIconLeft, popupIconMiddle, popupIconRight, thumb, slideShow, options, controls);

	}, [clientId, popupIconLeft, popupIconMiddle, popupIconRight, thumb, slideShow, options, controls]);

	const filteredItems = 'button' === lightboxType ? [items[0]] : items;

	const ButtonContent = () => <>
		{button.icon?.class && <i className={`icon ${button.icon?.class} `}></i>}
		<span>{button.text}</span>
	</>
	// check lightboxType
	if (!lightboxType) {
		return false
	}

	useEffect(() => {
		if (layout === 'default') {
			document.querySelector(`#lbbLightBox-${cId} .lbbLightBox`).style.height = 'auto';
		}

	}, [layout]);

	useEffect(() => {
		if (layout === 'default' || layout === 'masonry') {
			const Elements = document.querySelector(`#lbbLightBox-${cId} .f-thumbs`);

			if (Elements) {
				Elements.style.display = 'none';
			}
		}
	}, [layout]);


	const globalProps = {
		attributes, filteredItems, mixAllData, clientId, EImage, ButtonContent, EAudio, EVideo, galleryIcon, setActiveIndex, isOpen, custom, isBackend, activeIndex, ContentArea, Modal, setOpen
	}

	const masonryProps = {
		...globalProps,
		containerRef
	}

	return <div ref={containerRef} className={` ${lightboxType === 'gallery' ? `lbbLightBox ${layout} columns-${columns.desktop} columns-tablet-${columns.tablet} columns-mobile-${columns.mobile}` : 'lbbLightBox llbButton'}`} id={`lightbox-${index}`}>
		{layout === 'masonry' ? <MasonryLayout {...masonryProps} /> : layout === 'slider' ? <Slider {...globalProps} /> : <Default {...globalProps} />}

	</div>
}
export default Lightbox;
