
import Caption from '../layout/Child/Caption';
import ChildImage from '../layout/Child/ChildImage';
import Icon from '../layout/Child/Icon';


const Audio = ({ attributes, type, mixAllData, clientId, caption, content, EAudio, lightboxType, img, thumbnail, captionPosition, galleryIcon, ButtonContent }) => {
    const { content: showContent } = attributes
    return 'audio' === type && <a data-fancybox={`${mixAllData ? `allDataGallery-${clientId}` : `audio-gallery-${clientId}`}`} data-caption={caption} className="lbbmodalbtn" href={`${content === '' ? EAudio : content}`} data-type={`${content === '' ? '' : 'html5video'}`}>
        {"gallery" === lightboxType ? <div className={`contentArea ${showContent?.overlyColor && "overlyColor"}`}>
            <ChildImage img={img} thumbnail={thumbnail} />
            <Caption showContent={showContent} caption={caption} captionPosition={captionPosition} />
            <Icon galleryIcon={galleryIcon} type={type} />
        </div>
            : <button className='lbbBtnDesign'><ButtonContent /></button>
        }
    </a>

}
export default Audio;