import { placeholderImg } from '../../utils/links';
import Icon from '../layout/Child/Icon';

const Content = ({ type, lightboxType, img, thumbnail, setOpen, setActiveIndex, index, galleryIcon, ButtonContent, isOpen, activeIndex, Modal, clientId, ContentArea, item, custom }) => {
    return 'content' === type && <a>
        {"gallery" === lightboxType ?
            <div className="content-thumbnail ">
                <div className={`img ${img?.animation}`}>
                    <img src={thumbnail || placeholderImg} onClick={() => { setOpen(true); setActiveIndex(index); }} />
                </div>
                {/* {caption && <div className={`caption ${captionPosition?.position}`}>
                    <p>{caption}</p>
                </div>} */}
                <Icon type={type} galleryIcon={galleryIcon} />
            </div>
            : <button className='lbbBtnDesign' onClick={() => { setOpen(true); setActiveIndex(index); }}><ButtonContent /></button>
        }

        {isOpen && activeIndex === index && <Modal clientId={clientId} activeIndex={activeIndex} onRequestClose={() => setOpen(false)} className="lbbLightBoxModal" overlayClassName='lbbLightBoxModalOverlay' shouldCloseOnClickOutside={false}>
            <ContentArea item={item} index={activeIndex} custom={custom} />
        </Modal>}
    </a>

}
export default Content;