
import { getBoxValue, ratioCheck } from './utils/functions';
import { getTypoCSS, getColorsCSS } from '../../Components/utils/getCSS';

const Style = ({ attributes, clientId, index = 0 }) => {
	const { columnGap, rowGap, img, image, imgBorder, playerColor, overlyColor, captionTypo, captionColors, btnTypo, btnWidth, btnHeight, btnAlign, btnColors, btnHovColors, btnPadding, btnBorder, btnRadius, btnSpaceBetween, sliderHeight, popupTheme } = attributes;

	const mainSl = `#lbbLightBox-${clientId}`;
	const lightboxSl = `${mainSl} #lightbox-${index}`;
	console.log(`lbb_modal_area-${clientId}`);

	const newStyles = `
	${getTypoCSS('', btnTypo)?.googleFontLink}
	${getTypoCSS('', captionTypo)?.googleFontLink}
	${getTypoCSS(`${lightboxSl} button`, btnTypo)?.styles}
	${getTypoCSS(`${lightboxSl} .contentArea .caption p`, captionTypo)?.styles}

	${lightboxSl}{
		grid-gap: ${rowGap} ${columnGap};
		justify-content:${btnAlign}; 
	}

	${lightboxSl} a{
		width:${btnWidth};
		height:${btnHeight};
	}

	${lightboxSl} .contentArea .caption p {
		${getColorsCSS(captionColors)};
	}
	
	${mainSl} .default .contentArea .img{
		padding-top: ${ratioCheck(image?.ratio)}%;
	}

	${lightboxSl} img {
		
	}

	${lightboxSl} .lbbContent_area {
		border-radius:${img?.borderRadius}px;
		border:${getBoxValue(imgBorder)};
	}

	${lightboxSl} .lbbContent_area .contentArea::after {
		background:${overlyColor};
		border-radius:${img?.borderRadius}px;
	}
	
	${lightboxSl}.llbButton .flex{
		 justify-content: ${btnAlign}
	}

	${lightboxSl} .lbbBtnDesign {
		${getColorsCSS(btnColors)};
		padding:${getBoxValue(btnPadding)};
		border:${getBoxValue(btnBorder)};
		column-gap:${btnSpaceBetween};
		border-radius:${(getBoxValue(btnRadius))};
		width:100%;
		height:100%;
	}
	
	${lightboxSl} .lbbBtnDesign:hover{
		${getColorsCSS(btnHovColors)};
	}

	.plyr {
		--plyr-color-main: ${playerColor};
	}

	${mainSl} .slider a {
		width:100%;
		height:100%;
	}

	${lightboxSl} #lbbCarousel {
		height:${sliderHeight?.desktop};
	}
 
	.lbb_modal_area-${clientId} {
		--fancybox-bg:${popupTheme?.color};
	}

	@media (max-width: 768px) {
		${lightboxSl} #lbbCarousel { 
			height:${sliderHeight?.tablet}
		}
	}

	@media (max-width: 576px) { 
		${lightboxSl} #lbbCarousel {  
			height:${sliderHeight?.mobile}
		}
	}

`;

	return <style dangerouslySetInnerHTML={{
		__html: newStyles
	}} />;
}
export default Style;